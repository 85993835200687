<style lang="scss">

.picker-selected{
  font-weight: bold;
}

.exp-line-title {
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  margin-top: 0.10rem ;
}

.exp-line-status {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
  font-weight: 400;

  color: #3b5998;
}





.finish-panel {
  .panel-title {
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding: 0 15px;
  }

  .panel-content {
    padding: 15px;
  }

}
.wrap{
  display:flex;
  flex-direction:column;
  height:100%;
}
.footer0{
  height:40px;
  width: 50%;
  position: fixed;
  bottom:0;
  left:0;
  z-index: 10;
}
.footer1{
  height:40px;
  width: 50%;
  position: fixed;
  bottom:0;
  left:50%;
  z-index: 10;
}
.view1{
  color: black;
  margin-right: 8px;
  font-size: 0.14rem;
}
.mint-radiolist-label{
  padding-left: 0;
}
.mint-cell{
  display: inline-block;
}
.mint-radiolist-title{
  display: inline-block;
  color: black;
  font-size: 14px;

}
.app-header {
  height: 46px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  width: 100%;
  //border-bottom: solid 1px rgba(0, 0, 0, 0.03);
  //box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.08);
  z-index: 2;

  font-size: 16px;
  color: #000;
  font-weight: bold;
}

.header-left, .header-right {
  height: 100%;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;

}

.header-left {
  left: 0;
}

.header-right {
  right: 0;
}
.icon-left {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
}

.go-home {
  display: block;
  width: 21px;
  height: 19px;
  background: url("../assets/images/go-home.png");
  background-size: contain;
}
.payImg1 {

  right: 0;
  top: 50px;
  display: inline-block;

  .grid-cell-1-3{
    width: 100px;
    height: 100px;
  }
}

</style>
<template>
  <div>
    <div class="app-header" >
      设备维护编辑
      <div class="header-left" @click="$router.push({path:'/eq-maintain'})"><i class="iconfont icon-prev"></i></div>
      <div class="header-right" @click="goHome()"><i class="go-home"></i></div>
    </div>
    <Panel class="tabs">
      <template slot="title">
        <div :class="`tab${tabIndex===0?' active':''}`" @click="switchLicenses(0)">基本信息</div>
        <div :class="`tab${tabIndex===1?' active':''}`" @click="switchLicenses(1)">维护信息</div>
      </template>
      <div slot="content"  >
        <div class="flex-dir-row mt15" v-if="tabIndex===0">
          <div style="display: inline-block;visibility: hidden">&nbsp;&nbsp;aa</div>
          <span style="color: red">*</span>
        <mt-radio
            title="维护类型"
            v-model="maintainType"
            :options=maintainTypeslots>
        </mt-radio>

        </div>
        <div class="flex-dir-row mt15" v-if="tabIndex===0">
          <label class="line-label view1"><span style="color: red">*</span><span style="margin-left: 8px">设备名称</span></label>
          <div class="flex-item-auto">
            <input class="line-input" id="eqName" @click="showType" v-model="eqName"
                   @keydown="preventDefault" placeholder="设备名称"/>
          </div>
        </div>
        <div class="flex-dir-row mt15" v-if="tabIndex===0">
          <label class="line-label view1"><span style="color: red">*</span><span style="margin-left: 8px">维护时间</span></label>
          <div class="flex-item-auto">
            <input class="line-input" id="maintenDate" @click="openPicker" v-model="maintenDate" placeholder="维护时间"
                   />

          </div>
        </div>
        <div class="flex-dir-row mt15" v-if="tabIndex===0">
          <label class="line-label view1" >描述</label>
          <div class="flex-item-auto">
            <textarea class="line-input" placeholder="描述" rows="4" style="height: 70px" id="" maxlength="1000" v-model="remark"
            />
          </div>
        </div>
        <div class="flex-dir-row mt15" v-if="tabIndex===0" style="position:relative;">

          <label class="line-label view1"style="position: absolute;top: 8px" >相关文件</label>

            <FileUpload2 space="jsbFile"  @showfile1list="gettabIndex" :tabindex="0" max-size="10" ref="fileUpload2" :editable="true" class="payImg1" style="position: absolute;right: 0.20rem"/>
        </div>
        <div class="flex-dir-row mt15" v-if="tabIndex===1">
          <label class="line-label view1" >维护方</label>
          <div class="flex-item-auto">
            <input class="line-input" id="maintainer" @click="showTypemaintainer" v-model="maintainerName"
                   @keydown="preventMaintainer" placeholder="维护方"/>
          </div>
        </div>
        <div class="flex-dir-row mt15" v-if="tabIndex===1">
          <label class="line-label view1" >维护费用<br/>(元)</label>
          <div class="flex-item-auto">
            <input class="line-input"   placeholder="输入本次金额" v-model="maintenFee"
                   />
            <div style="display: none">{{addmaintenFee}}</div>
          </div>
        </div>
        <div class="flex-dir-row mt15" v-if="tabIndex===1">
          <label class="line-label view1" >维护耗时<br/>(小时)</label>
          <div class="flex-item-auto">
            <input type="number" class="line-input" min="0"  placeholder="输入本次花费时间" v-model="maintenTime"
            />
            <div style="display: none">{{addmaintenTime}}</div>
          </div>
        </div>
        <div class="flex-dir-row mt15" v-if="tabIndex===1">
          <label class="line-label view1" >计划下次<br/>维护时间</label>
          <div class="flex-item-auto">
            <input class="line-input"  @click="openPickerNext" placeholder="计划下次维护时间" v-model="maintenNextDate"
            />
          </div>
        </div>
        <div class="flex-dir-row mt15" v-if="tabIndex===1">
          <label class="line-label view1" >维护结果</label>
          <div class="flex-item-auto">
            <textarea class="line-input" rows="4" placeholder="维护结果" style="height: 70px"  maxlength="1000" v-model="maintenResult"
            />
          </div>
        </div>
        <div class="flex-dir-row mt15" v-if="tabIndex===1" style="position:relative;">
          <label class="line-label view1" style="position: absolute;top: 8px">相关文件</label>
          <div class="flex-item-auto">
          <FileUpload2 space="jsbFile" max-size="10" :tabindex="1" @showfile2list="gettabIndex" ref="fileUpload2" :editable="true" class="payImg1" style="position: absolute;right: 0.20rem"/>
          </div>
          </div>
      </div>
    </Panel>
    <div class="btn footer0" style="background-color: white;color: black!important; border: 1px #E6E6E6 solid" @click="cancel">取消</div>
    <div class="btn  green footer1" @click="save">保存</div>
    <mt-popup
        v-model="showTypeVisible"
        position="bottom"
    >
      <mt-picker :slots="typeSlots" @change="ontypeChange" value-key="text" v-if="showTypeVisible"></mt-picker>
    </mt-popup>
      <mt-popup
          v-model="showMaintainer"
          position="bottom"
      >
        <mt-picker :slots="maintainertypeSlots" @change="onMaintainersChange" value-key="text" v-if="showMaintainer"></mt-picker>
    </mt-popup>
    <mt-datetime-picker
        ref="picker"
        type="datetime"
        @confirm="format"
        v-model="maintenDate1">
    </mt-datetime-picker>
    <mt-datetime-picker
        ref="pickerNext"
        type="datetime"
        @confirm="formatNextDate"
        v-model="maintenNextDate1">
    </mt-datetime-picker>
  </div>
</template>

<script>
import AppHeader from "@/components/AppHeader";
import {InfiniteScroll , Radio, DatetimePicker} from 'mint-ui';
import {Toast, MessageBox} from 'mint-ui';
import dict from "@/plugins/dict";
import store from "@/plugins/store";
import Panel from "@/components/Panel";
import {mapState,mapActions} from "vuex";
import FileUpload2 from "@/components/FileUpload2";
export default {
  name: "eqmaintain",
  components: {
    Panel,
    AppHeader,
    InfiniteScroll,
    Radio,
    DatetimePicker,
    FileUpload2
  },
  data() {
    return {

      page: 1,
      tabIndex: 0,
      mainTain: null,
      maintainType: null,
      eqId: null,
      eqName: null,
      maintenDate: null,
      maintenDate1: new Date(),
      remark: null,
      maintainerName: null,
      maintainerCode: null,
      maintenFee: 0,
      maintenTime: 0,
      maintenNextDate: null,
      maintenNextDate1: new Date(),
      maintenResult: null,
      maintainTypes: [],
      loading: false,
      noMore: false,
      showTypeVisible: false,
      showMaintainer: false,
      id: null,
      //数据字典
      equipments: [],
      maintainers: [],

      //文件上传
      file1Ary: [],
      file2Ary: [],
      // dateVal: new Date(),
      // pickerField: '',

    }
  },

  computed: {
  ...mapState({
    // 为了能够使用 `this` 获取局部状态，必须使用常规函数
    maintainTypeslots(state) {

      var maintainTypes=[]
      var arys= state.maintainTypes
      for (var i=0;i<arys.length;i++){
        var ary= {
          label: arys[i].text,
          value: arys[i].id
        }
        maintainTypes.push(ary)
      }
      return maintainTypes;
    },
    typeSlots(state) {
      const that = this
      this.equipments=state.equipments;
      return [{values: state.equipments}]
    },
    maintainertypeSlots(state) {
      this.maintainers=state.maintainers
      return [{values: state.maintainers}]
    },
  }),
    addmaintenFee(){
    if(this.maintenFee){
      this.maintenFee = this.maintenFee.toString().replace(/[^\d.]/g, "");
      this.maintenFee = this.maintenFee.toString().replace(/^\./g, "");
      this.maintenFee = this.maintenFee.toString().replace(/\.{2,}/g, ".");
      this.maintenFee = this.maintenFee.toString().replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
      this.maintenFee = this.maintenFee.toString().replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3');
      if (this.maintenFee > 1000000) {
        this.maintenFee=1000000
        return 1000000
      }else {
        return this.maintenFee
      }
      return this.maintenFee
    }
    },
    addmaintenTime(){
      if(this.maintenTime){
        this.maintenTime = this.maintenTime.toString().replace(/[^\d.]/g, ""); //清除"数字"和"."以外的字符
        this.maintenTime = this.maintenTime.toString().replace(/^\./g, ""); //验证第一个字符是数字
        this.maintenTime = this.maintenTime.toString().replace(/\.{2,}/g, "."); //只保留第一个, 清除多余的
        this.maintenTime = this.maintenTime.toString().replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
        this.maintenTime = this.maintenTime.toString().replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); //只能输入两个小数
        if (this.maintenTime > 1000000) {
          this.maintenTime=1000000
          return 1000000
        }else {
          return this.maintenFee
        }
        return this.maintenFee
      }
    }

  },

  methods: {


    num(newVal,oldVal) {
        this.maintenFee = this.maintenFee.replace(/[^\d.]/g, ""); //清除"数字"和"."以外的字符
        this.maintenFee = this.maintenFee.replace(/^\./g, ""); //验证第一个字符是数字
        this.maintenFee = this.maintenFee.replace(/\.{2,}/g, "."); //只保留第一个, 清除多余的
        this.maintenFee = this.maintenFee.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
        this.maintenFee = this.maintenFee.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); //只能输入两个小数
        if (newVal > 1000000) {
          this.$data.maintenFee = 1000000
        }
    },
    initAddMainTain(){
      this.id=this.$route.query.mainTainId;
      this.mainTain=null;
      this.page = 1
      this.noMore = false
      if(this.id!=null){
        this.loadMainTain()
      }

    },


    loadMainTain(){
      if (this.noMore) {
        return
      }
      const that = this
      this.loading = true
      let data = new FormData()
      data.append('id',`${this.id}`)
      this.$http.post(`/api/equipmentMaintain/edit`, data).then(res=>{
        var data=res.data
        if (data){
          that.maintainType=data.maintenType
          if(data.equipmentId!=null){
            that.queryEqNameById(data.equipmentId)
          }
          if(data.maintenDate!=null){
            var a= this.$moment(data.maintenDate).format('YYYY-MM-DD HH:mm:ss')
            that.maintenDate=a
          }
          if(data.remark!=null){
            that.remark=data.remark
          }
          //文件上传
          if(data.fileId1!='null'&&data.fileId1!='[]'){
              var fileId1s=(data.fileId1.slice(2,-2)).replace(/","/g,",")
              var fileId1sAry=fileId1s.split(",")
            that.file1Ary=fileId1sAry
            this.$nextTick(()=>{
              this.$refs.fileUpload2.initFilesByUuids(fileId1sAry,this.tabIndex)
            })

          }

          if(data.fileId2!='null'&&data.fileId2!='[]'){
            var fileId2s=(data.fileId2.slice(2,-2)).replace(/","/g,",")
            var fileId2sAry=fileId2s.split(",")
            that.file2Ary=fileId2sAry
            // that.$refs.fileUpload.initFilesByUuids(fileId2sAry)
          }



          //维护方
          if(data.maintainer!=null){
            that.queryMaintainerBycode(data.maintainer)
          }
          if(data.maintenFee!=null){
              that.maintenFee=data.maintenFee
          }
          if(data.maintenTime!=null){
            that.maintenTime=data.maintenTime
          }
          if(data.maintenNextDate!=null){
            var a= this.$moment(data.maintenNextDate).format('YYYY-MM-DD HH:mm:ss')
            that.maintenNextDate=a
          }
          if(data.maintenResult!=null){
            that.maintenResult=data.maintenResult
          }


        }else {
          this.noMore = true
        }
      })
    },
    queryEqNameById(id){
      this.typeSlots;
      var eqAry= this.equipments
      if(eqAry.length>0){
        for (var i=0;i<eqAry.length;i++){
          if(id==eqAry[i].id){
            this.eqName=eqAry[i].text
            this.eqId=eqAry[i].id
          }
        }
      }
    },
    queryMaintainerBycode(code){
      this.maintainertypeSlots
      var mtAry=this.maintainers
      if(mtAry.length>0){
        for (var i=0;i<mtAry.length;i++){
          if(code==mtAry[i].id){
            this.maintainerName=mtAry[i].text
            this.maintainerCode=mtAry[i].id
          }
        }
      }

    },
    showPicker() {
      this.dateVal = new Date()
      this.pickerField = pickerField
      this.$refs.picker.open()
    },
    ontypeChange(picker, values) {
      this.eqName = values[0].text
      this.eqId = values[0].id
    },
    onMaintainersChange(picker, values) {
      this.maintainerName = values[0].text
      this.maintainerCode = values[0].id
    },
    gettabIndex(data){
      // debugger
      var files=data.files


      if(data.tabIndex==0){
        this.file1Ary=[]

        for (var i=0;i<files.length;i++){
          if(files[i].fid!=""&&files[i].fid!=null){
            this.file1Ary.push(files[i].fid)
          }

        }
      }
      if(data.tabIndex==1){
        this.file2Ary=[]
        for (var i=0;i<files.length;i++){

          if(files[i].fid!=""&&files[i].fid!=null){
          this.file2Ary.push(files[i].fid)
          }

        }
      }
    },
    switchLicenses(tabIndex){

      var that=this;
      this.tabIndex=tabIndex;
      setTimeout(function (){
        that.inFileByuuid(tabIndex)

      },150)

    },
    inswitchLicenses(tabIndex){
      var that=this;
      this.tabIndex=tabIndex;


    },
    inFileByuuid(tabIndex){
      if(tabIndex==0){
        this.$nextTick(()=>{
          this.$refs.fileUpload2.initFilesByUuids(this.file1Ary,this.tabIndex)
        })

      }
      if(tabIndex==1){
        this.$nextTick(()=>{
          this.$refs.fileUpload2.initFilesByUuids(this.file2Ary,this.tabIndex)
        })

      }
    },
    showType(){
      this.showTypeVisible = true
    },
    showTypemaintainer(){
      this.showMaintainer=true
    },
    preventDefault(e) {
      e.preventDefault()
    },
    preventMaintainer(e) {
      e.preventMaintainer()
    },
    openPicker() {
      // this.dateVal = new Date()
      // this.pickerField = pickerField
      this.$refs.picker.open();
    },
    openPickerNext() {
      this.$refs.pickerNext.open();
    },
    format(val){
      this.maintenDate=this.$moment(val).format('YYYY-MM-DD HH:mm:ss')
    },
    formatNextDate(val){
      this.maintenNextDate=this.$moment(val).format('YYYY-MM-DD HH:mm:ss')
    },
    goHome() {
      this.$router.push("/")
    },
    save(){

      if(this.maintainType!=null&&this.eqId!=null&&this.maintenDate){
        let data = new FormData()
        const that = this
        this.loading = true
        var file1= JSON.stringify(this.file1Ary)
        var file2= JSON.stringify(this.file2Ary)
        data.append('fileId1',file1)
        data.append('fileId2',file2)

        this.id&&data.append('id',`${this.id}`)
        this.maintainType&&data.append('maintenType',`${this.maintainType}`)
        this.eqId&&data.append('equipmentId',`${this.eqId}`)
        this.maintenDate&&data.append('maintenDate',`${this.maintenDate}`)
        this.remark&&data.append('remark',`${this.remark}`)
        this.maintainerCode&&data.append('maintainer',`${this.maintainerCode}`)
        this.maintenFee&&data.append('maintenFee',`${this.maintenFee}`)
        this.maintenTime&&data.append('maintenTime',`${this.maintenTime}`)
        this.maintenNextDate&&data.append('maintenNextDate',`${this.maintenNextDate}`)
        this.maintenResult&&data.append('maintenResult',`${this.maintenResult}`)
        this.$http.post(`/api/equipmentMaintain/save`, data).then(resp => {
          if (resp.success) {
            Toast("提交成功！")
            setTimeout(function (){
              that.$router.push({path: '/eq-maintain'})
            },200)
          }
        })
      }else {
        Toast("必填项不能为空")
      }


    },
    cancel(){
      this.$router.push({path: '/eq-maintain'})
    }


  },
  mounted() {

    this.tabIndex = parseInt(this.$route.query.tabIndex || '0')

    this.initAddMainTain()
    this.inswitchLicenses(this.tabIndex)

  }


}
</script>
